<template>
  <div>
    <EditTable class="school_list" primary-key="id" :items="schools" :fields="fields"
      :busy="loading" outlined striped hover selectable sort-icon-left
      @addNew="$router.push({name: 'bke-school-new'})"
      @refresh="getSchools"
      @deleteSelected="OnDeleteSelected"
    >
      <template v-slot:title>
        <h5>Schools</h5>
      </template>
      <template v-slot:cell(name)="cell">
        <router-link :to="{name:'bke-school-view', params:{school_id: cell.item.id}}">{{ cell.item.name }}</router-link>
      </template>
      <template v-slot:cell(contact)="cell">
        <AddressIcon /> {{ cell.item.address }}<br />
        <TelIcon /> {{ cell.item.telephone }}<br />
        <EmailIcon /> {{ cell.item.email }}
      </template>
      <template v-slot:cell(school_admins)="cell">
        <ul class="list-unstyled">
          <li class="text-nowrap" v-for="(admin, i) in cell.item.school_admins" :key="i">
            <router-link :to="{name:'user', params:{id: admin.id}}">{{ admin.display_name }}</router-link>
          </li>
        </ul>
      </template>
      <template v-slot:cell(coverimage)="cell">
        <div class="img_div"><b-img :src="cell.value" /></div>
      </template>
    </EditTable>
  </div>
</template>

<script>
import AddressIcon from 'mdi-vue/OfficeBuildingMarker';
import TelIcon from 'mdi-vue/PhoneClassic';
import EmailIcon from 'mdi-vue/EmailOutline';
import { mapState } from 'vuex';
import EditTable from '@/components/ui/EditTable.vue';

export default {
  name: 'BackendListSchool',
  components: {
    EditTable, AddressIcon, TelIcon, EmailIcon,
  },

  data() {
    return {
      loading: false,
      fields: [
        { key: 'name', sortable: true },
        { key: 'contact', label: 'Address' },
        { key: 'school_admins', label: 'Administrators' },
        { key: 'coverimage', label: 'Cover' },
      ],
    };
  },

  computed: {
    ...mapState('school', ['schools']),
  },

  methods: {
    getSchools() {
      this.loading = true;
      this.$store.dispatch('school/getAllSchools').catch(() => {}).finally(() => {
        this.loading = false;
      });
    },
    OnDeleteSelected(items) {
      this.loading = true;
      const schoolIds = items.map(x => x.id);
      this.$store.dispatch('school/deleteSchools', schoolIds).catch(() => {
        this.$toast.error('Failed to delete selected items. Try again later.');
      }).finally(() => {
        this.loading = false;
      });
    },
  },

  created() {
    if (!this.schools || (this.schools.length === 0)) this.getSchools();
  },

};
</script>

<style lang="stylus">

.school_list
  .img_div
    position relative
    width 3rem
    img
      width 100%

</style>
